const augment8Dashboard = 'dashboard';
const login = 'login';
const error = 'error';
const payout = 'payout';
const payoutInitialization = 'payout_initialization';
const inputFileUpload = 'input_file_upload';
const fileManagement = 'file_management';
const masterFileUpload = 'master_file_upload';
const masterDataSourceConnections = 'master_data_source_connections';
const maintainence = 'maintainence';
const inputFileDownload = 'input_file_download';
const masterFileDownload = 'master_file_download';
const myTask = 'my_task';
const unificationHistory = 'unification_history';
const payoutHistory = 'payout_history';
const profile = 'profile';
const accounting = 'accounting';
const dashboardMapping = 'dashboard_mapping';
const chartMapping = 'chart_mapping';
const dashboardChartMapping = 'dashboard_chart_mapping';
const chart = 'chart';
const unifiedSourceMaster = 'unified_source_master';
const overview = 'overview';

const resourceName = {
  augment8Dashboard,
  login,
  error,
  payoutInitialization,
  payout,
  inputFileUpload,
  fileManagement,
  masterFileUpload,
  masterDataSourceConnections,
  maintainence,
  inputFileDownload,
  masterFileDownload,
  myTask,
  unificationHistory,
  payoutHistory,
  profile,
  accounting,
  dashboardMapping,
  chartMapping,
  dashboardChartMapping,
  chart,
  unifiedSourceMaster,
  overview,
};

export default resourceName;
