// package imports
import { useTranslate } from '@refinedev/core';
import { Button, List, Space, Table } from 'antd';
import moment from 'moment';
// project imports
import constants from 'core/constants/Constants';
import { DownloadOutlined } from '@ant-design/icons';
import directFileDownload from 'core/utils/directFileDownload';
import apiEndpoint from 'core/common/apiEndPoints';
import resourceName from 'core/common/resourceName';
import { ShowButton } from '@refinedev/antd';
import logger from 'core/utils/logger';

const UnificationHistoryTable = ({
  tableProps,
  unificationHistoryTableData,
}) => {
  const t = useTranslate();
  const createRenderDate = (position) => (_, data) => {
    const dates = data?.date?.split(' - ');
    const date = moment(dates?.[position]);
    return date.format(constants.ddmmmmyyyy);
  };

  const renderStartDate = createRenderDate(0);
  const renderEndDate = createRenderDate(1);
  console.log('unificationHistoryTableData', unificationHistoryTableData);

  function formatStatus(status) {
    return status
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function renderDownloadButton(row) {
    return (
      <Button
        icon={<DownloadOutlined />}
        className="a8-show-button"
        onClick={() =>
          directFileDownload(
            `${
              apiEndpoint.downloadUnifiedFile
            }/?unified_source_master_id_list=${(row as any)
              ?.unified_source_master_id}&batch_id=${(row as any)?.id}`
          )
        }
      />
    );
  }
  function renderShowButtonUrl(row) {
    const startDate = moment(row.start_date).format('YYYY-MM-DD');
    const endDate = moment(row.end_date).format('YYYY-MM-DD');
    const columnDate = { startDate, endDate };
    logger('Meta data being passed :: -', columnDate);
    return (
      <ShowButton
        hideText
        resource={resourceName.unificationHistory}
        meta={columnDate}
        recordItemId={row?.unified_source_master_id}
        className="a8-edit-button"
      />
    );
  }

  return (
    <List>
      <Table
        {...tableProps}
        className="mt-3"
        bordered
        dataSource={unificationHistoryTableData}
        rowKey="id"
        scroll={{ x: 767 }}
      >
        <Table.Column
          dataIndex="iprogram_name"
          title={t('payoutHistory.payoutTableHeaders.program')}
        />
        <Table.Column
          dataIndex="frequency_name"
          className="capitalize"
          title={t('payoutHistory.payoutTableHeaders.frequency')}
        />
        <Table.Column
          dataIndex="workflow_name"
          title={t('payoutHistory.payoutTableHeaders.workflow')}
        />
        <Table.Column
          dataIndex="start_date"
          title={t('payoutHistory.payoutTableHeaders.startDate')}
          render={renderStartDate}
        />
        <Table.Column
          dataIndex="end_date"
          title={t('payoutHistory.payoutTableHeaders.endDate')}
          render={renderEndDate}
        />
        <Table.Column
          dataIndex="status"
          title={t('payoutHistory.payoutTableHeaders.status')}
          render={(status) => formatStatus(status)}
        />
        <Table.Column
          dataIndex="unified_source_master_name"
          title={t('payoutHistory.payoutTableHeaders.unifiedSourceName')}
          // render={(status) => formatStatus(status)}
        />
        <Table.Column
          dataIndex="action"
          title={t('table.actions')}
          render={(_, row: any) => (
            <Space size="large">
              {renderDownloadButton(row)}
              {renderShowButtonUrl(row)}
            </Space>
          )}
          width={150}
        />
      </Table>
    </List>
  );
};
export default UnificationHistoryTable;
